<template>
  <div class="mt-4">
    <div class="container">
      <div v-if="loader" class="d-flex justify-content-center">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>

      <table
        id="file-table"
        v-show="!loader"
        class="table table-bordered mt-3 "
      >
        <tbody>
          <tr v-for="file in files" :key="file.id">
            <td>
              <a href="#" @click.prevent="openUrl(file.path)">{{
                file.filename
              }}</a>
            </td>
            <td>{{ file.description }}</td>

            <td>{{ file.created_at | dateTime }}</td>
          </tr>
        </tbody>
      </table>

      <div class="form-group">
        <a href="#collapse-form" data-toggle="collapse"
          ><i class="bi bi-cloud-arrow-up icon-sm mr-1"></i> Click here to
          upload files</a
        >
      </div>

      <div class="collapse" id="collapse-form">
        <form @submit.prevent="uploadFile">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="alert alert-warning">
                <i class="bi bi-exclamation-circle icon-sm mr-1"></i> To
                expedite the processing of your application, make sure the file
                you provide solves all of the issues indicated in the
                comments/remarks.
              </div>
              <div class="form-group col-5 px-0">
                <label for="">File Type</label>
                <select
                  name=""
                  class="form-control"
                  id=""
                  v-model="form.file_type"
                  required
                >
                  <option value="PAY">Payment</option>
                  <option value="CAV">Document</option>
                </select>
                <small class="form-text text-muted"
                  >Select "Payment" for any payment related file or "Document"
                  for requirements.</small
                >
              </div>

              <div class="form-group">
                <label for="">Select File</label>
                <input
                  id="upload-file-input"
                  type="file"
                  class="form-control-file"
                  accept="application/pdf"
                  @change="getFile"
                  required
                />
                <small class="form-text text-danger"
                  >Make sure to upload PDF files only.</small
                >
              </div>

              <div class="form-group">
                <button
                  class="btn btn-primary"
                  type="submit"
                  id="upload-file-btn"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Files',
  props: {
    app: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loader: '',
      files: [],
      updatedRemarks: '',
      form: new Form({
        file_type: '',
        file: '',
      }),
      prefix: 'https://drive.google.com/file/d/',
    };
  },
  methods: {
    getFiles() {
      this.loader = true;

      axios
        .get('api/files/' + this.app.id)
        .then((res) => {
          this.files = res.data;
          this.loader = false;
        })
        .catch(() => {
          toastr.error('Something went wrong.', 'Error');
          this.loader = false;
        });
    },

    uploadFile() {
      let btn = document.getElementById('upload-file-btn');
      btn.disabled = true;
      btn.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Uploading...`;

      let fd = new FormData();

      fd.append('app_id', this.app.id);
      fd.append('file_type', this.form.file_type);
      fd.append('file', this.form.file);

      axios
        .post('api/files', fd)
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = 'Upload';
          $('#collapse-form').collapse('hide');
          this.getFiles();
        })
        .catch((err) => {
          toastr.error('Something went wrong.', 'Error');
        });
    },
    getFile(event) {
      this.form.file = event.target.files[0];
    },
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
  },

  mounted() {
    this.getFiles();
  },
};
</script>
