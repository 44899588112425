<template>
  <div>
    <div class="modal" id="special-order-modal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-6">
                <div class="row">
                  <div class="col">
                    <div class="">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search First Name or Last Name"
                        v-model="searchValue"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <button
                      v-if="app.status_id != 4"
                      class="btn btn-primary"
                      @click="openUnitEarnerModal"
                      :disabled="
                        app.student_type !== 'UE' || app.secpa_no !== null
                      "
                    >
                      Unit Earner
                    </button>
                  </div>
                </div>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="close float-right"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="mb-2" v-if="app.secpa_no">
              <p class="text-danger">
                For this application, the CAV has already been generated. SECPA
                No: <b>{{ app.secpa_no }}</b>
              </p>
            </div>

            <table class="table table-sm table-bordered text-uppercase">
              <thead>
                <th>#</th>
                <th width="12%">Name</th>
                <th>Program</th>
                <th>Date Started</th>
                <th>Date Ended</th>
                <th>Grad. Date</th>
                <th>Special Order</th>
                <th>Institution</th>
                <th>Issued on</th>
                <th v-if="app.status_id != 4">Action</th>
              </thead>
              <tbody>
                <tr class="text-center" v-show="tableLoader">
                  <td colspan="13">
                    <div class="spinner-border spinner-border-sm mr-2"></div>
                    Loading...
                  </td>
                </tr>
                <tr
                  class="text-center"
                  v-if="!filteredSpecialOrders.length && !tableLoader"
                >
                  <td colspan="13">No results found.</td>
                </tr>
                <tr v-for="so in filteredSpecialOrders" :key="so.id">
                  <th>{{ so.id }}</th>
                  <td>
                    {{ so.firstName }} {{ so.middleName }} {{ so.lastName }}
                  </td>
                  <td>{{ so.progName }}</td>
                  <td>{{ so.semEnrolled }} ({{ so.acadYear }})</td>
                  <td>{{ so.semStarted }} ({{ so.semYear }})</td>

                  <td>{{ so.gradDate | standardDate }}</td>
                  <td>
                    <a
                      v-if="so.path"
                      href="#"
                      @click.prevent="openUrl(so.path)"
                    >
                      {{ so.soLevel }} {{ so.soRegion }} {{ so.soNumber }}
                      {{ so.soSequence }} {{ so.soSeries }}
                    </a>
                    <span v-else
                      >{{ so.soLevel }} {{ so.soRegion }} {{ so.soNumber }}
                      {{ so.soSequence }} {{ so.soSeries }}</span
                    >
                  </td>
                  <td>{{ so.schoolName }}</td>
                  <td>{{ so.soDateIssued }}</td>
                  <td v-if="app.status_id != 4">
                    <a
                      href="#"
                      v-show="so.path && app.secpa_no == null"
                      @click.prevent="openCavModal(so)"
                      >Generate CAV</a
                    >
                    |
                    <a href="#" @click.prevent="openUploadSoModal(so)"
                      >Upload SO</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <GenerateCAV
      ref="GenerateCAV"
      @openModal="openModal"
      v-on:print="print"
      v-on:getApps="_getApps"
    ></GenerateCAV>
    <PrintCAV v-show="false" ref="PrintCAV"></PrintCAV>
    <UploadSO ref="UploadSO"></UploadSO>
    <UnitEarnerModal
      ref="UnitEarnerModal"
      v-on:print="print"
      v-on:getApps="_getApps"
    ></UnitEarnerModal>
  </div>
</template>

<script>
import PrintCAV from './PrintCAV.vue';
import GenerateCAV from './GenerateCAV.vue';
import UploadSO from './UploadSO.vue';
import UnitEarnerModal from './UnitEarnerModal.vue';

export default {
  name: 'VerifySpecialOrder',
  components: {
    PrintCAV,
    GenerateCAV,
    UploadSO,
    UnitEarnerModal,
  },
  data() {
    return {
      searchValue: '',
      tableLoader: true,
      sos: [],
      form: new Form({}),
      app: '',
      prefix: 'https://drive.google.com/file/d/',
    };
  },
  methods: {
    openModal(app) {
      $('#special-order-modal').modal('show');
      this.searchSpecialOrder(app.firstname, app.lastname);
      this.app = app;
    },

    searchSpecialOrder(firstname, lastname) {
      this.sos = [];
      this.tableLoader = true;
      axios
        .get(
          '/api/old-database/special-orders/fn/' + firstname + '/ln/' + lastname
        )
        .then((res) => {
          this.sos = res.data;
          this.tableLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
    print(cav) {
      this.$refs.PrintCAV.print(cav);
    },

    openCavModal(so) {
      $('#special-order-modal').modal('hide');
      this.$refs.GenerateCAV.openModal(so, this.app);
    },

    openUploadSoModal(so) {
      $('#special-order-modal').modal('hide');
      this.$refs.UploadSO.openModal(so, this.app.abbrev);
    },

    openUnitEarnerModal() {
      $('#special-order-modal').modal('hide');
      this.$refs.UnitEarnerModal.openModal(this.app);
    },

    _getApps() {
      this.$emit('updateTable');
    },
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
  },
  computed: {
    filteredSpecialOrders() {
      let temp = this.sos;

      if (this.searchValue != '') {
        temp = temp.filter((so) => {
          return (
            so.firstName
              .toUpperCase()
              .includes(this.searchValue.toUpperCase()) ||
            so.lastName.toUpperCase().includes(this.searchValue.toUpperCase())
          );
        });
      }

      return temp;
    },
  },
  mounted() {},
};
</script>
