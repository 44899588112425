<template>
  <div>
    <div class="modal" id="upload-so-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="uploadSO">
            <div class="modal-body">
              <div class="form-group">
                <label for="">SO Number</label>
                <h5>
                  {{ so.soLevel }} {{ so.soRegion }} {{ so.soNumber }}
                  {{ so.soSequence }} {{ so.soSeries }}
                </h5>
              </div>

              <div class="form-group">
                <label for="">File</label>
                <input
                  id="upload-so-file-input"
                  type="file"
                  class="form-control-file"
                  accept="application/pdf"
                  @change="getFile"
                  required
                />
                <small class="form-text text-muted"
                  >File must be on PDF format only</small
                >
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button id="upload-so-btn" type="submit" class="btn btn-primary">
                Upload
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      so: '',
      file: '',
      abbrev: '',
    };
  },
  methods: {
    openModal(so, abbrev) {
      $('#upload-so-modal').modal('show');
      this.so = so;
      this.abbrev = abbrev;
    },
    uploadSO() {
      let btn = document.getElementById('upload-so-btn');
      btn.innerHTML = `<div class="spinner-border spinner-border-sm mr-2"></div> Uploading...`;
      btn.disabled = true;

      let fd = new FormData();

      fd.append('soLevel', this.so.soLevel);
      fd.append('soRegion', this.so.soRegion);
      fd.append('soNumber', this.so.soNumber);
      fd.append('soSequence', this.so.soSequence);
      fd.append('soSeries', this.so.soSeries);
      fd.append('soDateIssued', this.so.soDateIssued);
      fd.append('abbrev', this.abbrev);
      fd.append('file', this.file);

      axios
        .post('api/old-database/special-orders/file-upload', fd)
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = 'Upload';
          $('#upload-so-modal').modal('hide');
          toastr.success(res.data.msg, 'Information');
        })
        .catch((err) => {
          btn.disabled = false;
          btn.innerHTML = 'Upload';
          toastr.error('Something went wrong', 'Error');
        });
    },
    getFile(event) {
      this.file = event.target.files[0];
    },
  },
};
</script>
