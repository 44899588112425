<template>
  <div class="mt-4">
    <div class="container">
      <div v-if="loader" class="d-flex justify-content-center">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>

      <div v-if="showNoPaymentMsg" class="text-center">
        <span>Payment not found.</span>
      </div>

      <div v-if="!loader && showNoPaymentMsg == false">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>Payment ID</td>
              <th>{{ payment.id }}</th>
            </tr>
            <tr>
              <td>Amount Paid</td>
              <th>
                <span v-show="payment.amount"
                  >&#8369; {{ payment.amount }}</span
                >
              </th>
            </tr>
            <tr>
              <td>Payment confirmed on</td>
              <th>{{ payment.receipt_date | standardDate }}</th>
            </tr>
            <tr>
              <td>CAV Control No</td>
              <th>{{ payment.control_no }}</th>
            </tr>
            <tr>
              <td>LBP Conf. No</td>
              <th>{{ payment.receipt_no }}</th>
            </tr>
            <tr>
              <td>LBP Reference No</td>
              <th>{{ payment.reference_no }}</th>
            </tr>

            <tr>
              <td>Updated on</td>
              <th>{{ payment.updated_at | dateTime }}</th>
            </tr>
            <tr>
              <td>By</td>
              <th>{{ payment.updated_by }}</th>
            </tr>
            <tr>
              <td>Remarks</td>
              <th>{{ payment.payment_remarks }}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentDetails',
  props: {
    app: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loader: false,
      showNoPaymentMsg: false,
      payment: {},
    };
  },
  methods: {
    getPaymentDetails() {
      if (!this.hasPayment()) {
        this.showNoPaymentMsg = true;
        return;
      }

      this.showNoPaymentMsg = false;
      this.loader = true;

      axios
        .get('api/cav-payments/' + this.app.payment_id)
        .then((res) => {
          this.payment = res.data;
          this.loader = false;
        })
        .catch((err) => {
          toastr.error('Unable to fetch payment details.', 'Error');
          this.loader = false;
        });
    },
    hasPayment() {
      return this.app.payment_id !== null && this.app.payment_id !== '';
    },
  },
  mounted() {
    this.getPaymentDetails();
  },
};
</script>
